@font-face {
  font-family: 'syncopate';
  src: url(https://create.infty.art/static/media/Syncopate-Regular.a02c5c1c.ttf);
}

@font-face {
  font-family: 'syncopate';
  src: url(https://create.infty.art/static/media/Syncopate-Bold.32992b61.ttf);
  font-weight: bold;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  background: rgb(53,75,94);
  background: linear-gradient(145deg, rgba(53,75,94,1) 0%, rgba(71,95,119,1) 100%);
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Roboto';
  color: white;
}

#root { 
  height: 100%;
}

h1 {
  font-family: 'syncopate';
  font-size: 40px;
  margin: 0;
}

h2 {
  font-family: 'syncopate';
  font-size: 36px;
  margin: 0;
}

h3 { 
  font-family: 'syncopate';
  font-size: 20px;
  margin: 0;
}

h4 {
  font-family: 'Roboto';
  font-size: 20px;
  margin: 0;
}