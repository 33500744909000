.prime .form {
  width: 250px;
  min-height: 330px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.prime .vector {
  margin-bottom: 25px;
}

.prime .group {
  margin-bottom: 70px;
}

button.primary {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: #0038ff;
  border: none;
  color: white;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: "Roboto";
  font-size: 18px;
}

button.primary:hover {
  background-color: #557aff;
}

.prime img {
  width: 100%;
}

.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.block-login {
  text-align: center;
  height: 60px;
  color: white;
  padding-bottom: 20px;
}

.block-login p {
  margin: 0;
}

.clear {
  text-decoration: underline;
  font-size: 18px;
  color: white;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.clear:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.clear:active {
  outline: none;
  border: none;
}

.clear:focus {
  outline: 0;
}

.back {
  margin-top: 5px;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.back img {
  height: 40px;
  width: 40px;
}

.reg,
.sign,
.main,
.confirm {
  width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reg .form,
.sign .form,
.reset .form,
.main .window,
.confirm .form {
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
}

.form > h2,
.window > h2,
.window > h3,
.form > h3 {
  margin-top: 80px;
  margin-left: 20px;
}

.user-window {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.user-window h2 {
  padding: 40px 0px 40px 20px;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.user-window .desc {
  padding: 0px 0px 40px 20px;
  max-width: 600px;
  font-size: 18px;
  margin: auto;
}

.exit-block {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 100px 20px;
  max-width: 600px;
  margin: auto;
}

.project {
  padding-top: 30px;
  padding-bottom: 50px;
}
div.back-block {
  padding: 20px 0px 0px 20px;
  max-width: 600px;
  margin: auto;
  position: absolute;
}

button.back-block {
  height: 30px;
  width: 30px;
  border: none;
  background: center / contain no-repeat url(https://create.infty.art/images/back.png);
}

.exit-block .exit {
  height: 40px;
  width: 40px;
  border: none;
  background: center / contain no-repeat url(https://create.infty.art/images/png/exit.png);
}

.profile-name {
  margin-left: 20px;
  padding-top: 30px;
  text-transform: uppercase;
  color: #0038ff;
  font-family: "syncopate";
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.profile-form {
  background-color: white;
  border-radius: 30px 30px 0px 0px;
  height: 100%;
  min-height: 680px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
}
.profile-form > .form {
  max-width: 400px;
  width: 100%;
  margin-bottom: 60px;
}

.profile-form > .form label {
  color: #0038ff;
}

.profile-form > .form span {
  color: #0038ff;
}

.profile-form > .form input {
  border-color: #0038ff;
  color: black;
}

.profile-form > .form textarea {
  border-color: #0038ff;
  color: black;
}

.profile-desc {
  text-align: center;
  color: #333333;
  font-size: 20px;
  padding-top: 30px;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.profile-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons-name {
  width: 75%;
  padding: 7px 5px 7px 30px;
}

.buttons-name > h3 {
  color: #0038ff;
}

.profile-button {
  color: black;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  max-width: 550px;
  padding: 7px 5px 7px 15px;
  margin-bottom: 20px;
  border: 1px rgba(0, 0, 0, 0.06) solid;
  border-radius: 5px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.06);
}

.profile-button:hover {
  background-color: rgba(186, 161, 255, 0.2);
  border: 1px rgba(0, 0, 0, 0) solid;
  cursor: pointer;
}

.profile-button .icon {
  width: 30px;
  height: 30px;
  background: center / contain no-repeat url("https://create.infty.art/images/png/arrow_btn.png");
}

.profile-button .name {
  font-size: 24px;
}

.profile-buttons {
  padding-top: 30px;
}

.details-window {
  width: 100%;
}

.details-window .imgs {
  height: 500px;
}

.details-window .img {
  height: 450px;
  min-width: 450px;
  margin-right: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.input {
  margin: 30px 0;
  padding: 0px 30px 0px 30px;
}

.input label {
  display: block;
  margin-bottom: 10px;
  color: #c4c4c4;
}

.input input {
  color: white;
  outline: 0;
  background: none;
  border-width: 0 0 1px;
  border-color: #c4c4c4;
  width: 100%;
  font-size: 18px;
  margin-bottom: 5px;
}

.input select {
  width: 100%;
  background: none;
  border-width: 0 0 1px;
  border-color: #c4c4c4;
  color: white;
  font-size: 18px;
}

.area {
  margin: 50px 100px 50px 20px;
}

.input .select {
  width: 100%;
  color: white;
}

.input .select .MuiSelect-icon {
  color: white;
}

.input .select:before {
  border-color: #c4c4c4 !important;
}

.input textarea {
  background: none;
  border: 1px #c4c4c4 solid;
  border-radius: 10px;
  color: white;
  width: 100%;
  height: 150px;
}

.input textarea:focus {
  border: none;
}

.input textarea:active {
  border: none;
}

.input .checkbox {
  width: unset;
}

.input span {
  color: #c4c4c4;
  font-size: 12px;
}

.terms {
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
}

.terms a {
  text-decoration: underline;
}

.frg-link {
  padding-top: 10px;
  float: right;
  font-size: 14px;
  color: #dcddd8;
}

.buttons-group {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
}

.buttons-group button {
  width: 50%;
  margin: 5px;
  font-size: 16px;
  color: white;
  padding: 15px 30px;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid white;
  border-radius: 10px;
  text-shadow: 0px 0px 0px transparent;
}

.buttons-group button:nth-child(2)  {
  background-color: white;
  color: #0038FF;
}

.buttons-group button:hover {
  background: rgb(79, 106, 129);
}

.sub-button {
  width: 50% !important;
  margin: 10px 15px !important;
  font-size: 16px;
  color: white;
  padding: 10px 0px;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid white !important;
  border-radius: 10px;
  text-shadow: 0px 0px 0px transparent;
}

.sub-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.submenu {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background-color: #0038ff;
  max-width: 330px;
  border-radius: 20px 20px 0 0;
}

.submenu button {
  height: 40px;
  width: 40px;
  margin: 10px 20px;
  background-color: #0038ff;
  border: none;
}

.submenu button:hover {
  cursor: pointer;
}

.home {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/home.png");
}

.home:not(.selected):hover {
  border: 1px rgba(255, 255, 255, 0) solid;
  border-radius: 5px;
}

.home.selected {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/home-selected.png");
}

.chat {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/support.png");
}

.chat:not(.selected):hover {
  border: 1px rgba(255, 255, 255, 0) solid;
  border-radius: 5px;
}


.notif {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/notification.png");
}

.notif.selected {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/notification-selected.png");
}

.notif:not(.selected):hover {
  border: 1px rgba(255, 255, 255, 0) solid;
  border-radius: 5px;
}

.profile {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/user.png");
}

.profile.selected {
  background: center / contain no-repeat url("https://create.infty.art/images/png/menu/user-selected.png");
}

.profile:not(.selected):hover {
  border: 1px rgba(255, 255, 255, 0) solid;
  border-radius: 5px;
}


.error-block > .error {
  padding-top: 30px;
}

p.error {
  color: red;
  margin: 0;

}

.add-line {
  text-align: center;
  margin-bottom: 75px;
}

.add-photo {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.add-photo button {
  height: 40px;
  width: 40px;
  background: center / contain no-repeat url(http://create.infty.art/images/add-line.png);
  border: none;
}

.add-line button,
.load button {
  height: 50px;
  width: 50px;
  margin: 10px 20px;
  background: center / contain no-repeat url("http://create.infty.art/images/add-line.png");
  border: none;
}

.load {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 280px;
  height: 280px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.images-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.images-box img {
  height: 70px;
  border-radius: 10px;
}

.buttons-box {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-box button {
  height: 25px;
  color: white;
  background: none;
  border: 1px white solid;
  border-radius: 5px;
}

.element {
  margin-top: 20px;
}

.element button {
  border: none;
  background: none;
}

.header *:first-child {
  margin-left: 20px;
}

.header *:last-child {
  margin-right: 10px;
}

.element .header {
  display: flex;
  justify-content: space-between;
}

.element h2 {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-window .project-box {
  margin: auto;
  position: absolute;
  width: 100%;
  padding-bottom: 100px;
}

.user-window .projects {
  max-width: 600px;
  margin: auto;
}

.user-window .projects button {
  padding-right: 30px;
}

.element > .imgs,
.user-window .imgs {
  height: 150px;
  margin-top: 20px;
  margin-left: 20px;
}

.window .imgs .img,
.user-window .imgs .img {
  flex: 0 0 auto;
  width: 150px;
  height: 140px;
  margin-right: 15px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.window .imgs::-webkit-scrollbar,
.user-window .imgs::-webkit-scrollbar,
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.title-bar {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 50px;
}

.detail-bar {
  max-width: 450px;
  margin: auto;
  padding-left: 30px;
}

.name-bar h2 {
  max-width: 50%;
  font-size: 22px;
}

.name-bar p {
  text-align: center;
  max-width: 50%;
  padding: 0;
  margin: 0;
}

.info-bar {
  margin-bottom: 100px;
}

.loading-bar {
  text-align: center;
}

.border-botom {
  /*border: 1px solid white;*/
}

.photo {
  height: 130px;
  width: 130px;
  margin-top: -80px;
  border-radius: 100px;
  border: 5px solid #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-user {
  padding: 0px 0px 40px 20px;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profile-user > .profile-name {
  font-size: 20px;
  padding-top: 0px;
  padding-left: 20px;
  color: white;
}

.profile-user > .photo {
  height: 50px;
  width: 50px;
  min-width: 50px;
  margin-top: 0px;
  border-radius: 100px;
  border: 1px solid #ffffff;
}

.user-window > .photo {
  position: relative;
  margin-top: 0px;
  border: 2px solid #ffffff;
  margin: auto;
  margin-bottom: 40px;
}

.absolute {
  position: absolute;
  width: 100%;
  margin: auto;
}

.no-type {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-type > img {
  height: 50px;
}

.no-type > p {
  margin: 0px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70px;
  height: 1.2em;
  white-space: nowrap;
}

.detail .no-type,
.notify .no-type,
.projects .no-type {
  margin-right: 15px;
}

.notify {
  padding-left: 20px;
}

.notify > hr {
  padding-right: 20px;
}

.detail .no-type {
  justify-content: center;
}

.HorizontalScrollInnerChildren > .no-type {
  height: 100%;
}

.detail .no-type > img,
.notify .no-type > img,
.projects .no-type > img {
  height: 120px;
}

.detail .no-type > p,
.notify .no-type > p,
.projects .no-type > p {
  width: 150px;
}

.HorizontalScrollTrack {
  display: none;
}

.horizontal-scroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}